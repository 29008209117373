import PiCard from '../pi/PiCard.vue';
import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance,onActivated} from 'vue';
import FinanceManagerPiListUtil ,{IFinanceManagerPiListDataObj} from './financeManagerPiListUtil';

export default defineComponent ({
    name: 'FinanceManagerPiList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IFinanceManagerPiListDataObj=reactive<IFinanceManagerPiListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'财务总监审核',
                isShowFixCol:false,
                paramHeight:54,//tab的高度
                queryParam: {},
                cardFrom:'FinanceManagerPiList',
                modelComp: PiCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/financeManagerPi/pageData')
            },
            otherParams:{
                activeName:'all',
                count:0,
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new FinanceManagerPiListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            dataObj.pageList.queryParam.queryType='all';
        })
        onActivated(()=>{

        })
        //---------------------------computed---------------------------

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //tab切换事件
        const handleChange=(tabName:string)=>{
            dataObj.pageList.queryParam.queryType=tabName;
            dataObj.pageListRef.queryHandler(true);
        }
        const gridLoaded=(res:any)=>{
            dataObj.otherParams.count=res.count;
        }
        return{
            ...toRefs(dataObj),formatPageInfo,handleChange,gridLoaded
        }
    }
});