import BaseBean from "@/utils/BaseBean";

export interface IFinanceManagerPiListDataObj {
    utilInst:FinanceManagerPiListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class FinanceManagerPiListUtil extends BaseBean{
    public dataObj:IFinanceManagerPiListDataObj

    constructor(proxy:any,dataObj:IFinanceManagerPiListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}